import React, { useState, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CustomIcon, ProgressBar } from '../atoms';
import { toast } from 'react-toastify';
import { getPresignedUploadLink, uploadFileToS3 } from '../utils/global';

export interface FileUploadProps {
  label: string;
  value: string;
  showSuccess?: boolean;
  changehandler?: (url: string) => void;
  uploadDir?: string;
  filetypes?: 'image/*' | 'video/*';
  disableDisplay?: boolean;
}

export const FileUpload = (props: FileUploadProps): JSX.Element => {
  const [progress, setProgress] = useState<number>();
  const [grabbing, setGrabbing] = useState<boolean>(false);
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!grabbing) {
      setGrabbing(true);
    }
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!grabbing) {
      setGrabbing(true);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      handleFileDrop(files);
    }
  };

  const uploadToS3 = async (file: any) => {
    getPresignedUploadLink(file.type, file.type.split('/')[1])
      .then((data) => {
        uploadFileToS3(data, file, setProgress)
          .then((data) => handleSuccess(data as string))
          .catch((e) => {
            console.error(e);
            toast.error('an error occurred');
          });
      })
      .catch((e) => {
        toast.error('could not retrieve upload link');
      });
  };

  const handleSuccess = (filepath: string) => {
    setProgress(undefined);
    if (props.showSuccess) {
      toast('🦄 File saved!');
    }
    if (props.changehandler) {
      // props.changehandler(filepath);
      const path = filepath.replace('s3.amazonaws.com/', '');
      props.changehandler(path);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      uploadToS3(e.target.files[0]);
    }
  };

  const handleFileDrop = (files: FileList) => {
    if (!['video', 'image'].includes(files[0].type.split('/')[0])) {
      toast.error('Invalid file type');
      return;
    }
    uploadToS3(files[0]);
  };

  const fileInputField = useRef<HTMLInputElement>(null);
  return (
    <>
      <Box
        onClick={() => fileInputField?.current?.click()}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        sx={{
          border: 1,
          borderColor: 'info.main',
          borderStyle: 'dashed',
          borderRadius: 3,
          height: 150,
          minWidth: { xs: 150, sm: 150 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: grabbing ? 'grabbing' : 'pointer',
          p: 5,
          mb: 2,
          '&:hover': {
            backgroundColor: 'info.main',
            color: 'white',
          },
        }}
      >
        <input
          type="file"
          accept={props.filetypes ? props.filetypes : '*'}
          ref={fileInputField}
          title=""
          value=""
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        <CustomIcon name="upload" />
        <Typography sx={{ textAlign: 'center' }}>{props.label}</Typography>
      </Box>
      {progress && <ProgressBar progress={progress} />}
      {props.value && !props.disableDisplay && (
        <Grid
          id="progress-container"
          sx={{
            pt: 2,
            width: { sx: '50%', md: '100%' },
            backgroundColor: '#EBEBEC',
          }}
        >
          <img
            src={props.value}
            width="50%"
            alt="file upload"
            crossOrigin="anonymous"
          />
        </Grid>
      )}
    </>
  );
};
